.portada {
    /*background-image: url("../img/bg-piloto.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position-y: -40px;*/
    /*width: 100%;*/
    /*height: 708px;*/
}

.portada h1 {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 15rem;
    color: white;
    margin-top: 20%;
    line-height: 124px;
}
.portada-text h2 {
    font-weight: 100;
    font-size: 9rem;
    color: white;
    margin-top: 20%;
    line-height: 100px;
    text-shadow: 6px 7px 11px rgba(0, 0, 0, 0.54);
    border-bottom: 10px #eb0029 solid;
}
.portada h5 {
    font-weight: 200;
    color: white;
    font-size: 2.6rem;
}

@media(max-width: 880px){
    .portada h1{
        font-size: 10rem;
        margin-top: 8%;
        line-height: 90px;
    }
    .portada-text h2 {
        font-weight: 100;
        font-size: 8rem;
        line-height: 90px;
        margin-top: 8%;
        max-width: 462px;
    }
    .portada h5 {
        font-size: 2rem;
    }
}

@media(max-width: 612px){
    .portada .owl-nav{
        display: none;
    }
    .portada h1{
        font-size: 7rem;
        margin-top: 8%;
        line-height: 60px;
        max-width: 500px;
    }
    .portada-text h2 {
        font-weight: 100;
        font-size: 5rem;
        line-height: 50px;
        margin-top: 8%;
        max-width: 285px;
    }
    .portada h5 {
        font-size: 1rem;
    }
}

@media(max-width: 430px){
    .portada h1{
        font-size: 4rem;
        margin-top: 8%;
        line-height: 35px;
        max-width: 250px;
    }
    .portada-text h2 {
        font-weight: 100;
        font-size: 3rem;
        line-height: 30px;
        margin-top: 8%;
        max-width: 170px;
    }
    .portada h5 {
        font-size: 1rem;
    }
}

.black-bar {
    background-color: black;
    margin-top: -1px;
    /*height: 80px;*/
}

.black-bar a {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
}

.black-bar a:hover {
    font-family: "Myriad Pro";
    font-weight: 200;
    color: white;
    text-decoration: none;
}

.black-bar div {
    padding-top: 11px;
    padding-bottom: 10px;
}

.productos-destacados {
    width: 100%;
    margin-bottom: 50px;
}

.productos-destacados h2 {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #676868;
    font-size: 45px;
    margin-top: 130px;
}
@media(max-width: 480px){
    .productos-destacados h2 {
        font-size: 30px;
    }
}
.productos-destacados col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-right: 5px;
    padding-left: 5px;
}

.productos-destacados .container-producto {
    position: relative;
    max-width: 451px;
    height: auto;
    border: 1px solid #676868;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.productos-destacados .container-producto img {
    max-width: 100%;
    height: auto;
}

.productos-destacados .container-producto h3 {
    font-family: "Myriad Pro";
    font-weight: 300;
    color: #676868;
    font-size: 20px;
    position: absolute;
    margin-left: 25px;
    margin-top: 25px;
}

.productos-destacados .container-producto Button {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background: transparent;
    border: none;
    background-color: #ec0928;
    border-radius: 0;
    color: white;
    text-shadow: none;
    padding-right: 22px;
    padding-left: 22px;
}

.foto-sensor {
    background-image: url("../img/bg-empresa3.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

@media (min-width: 1500px) {
    .foto-sensor {
        /*background-position-y: -120px;*/
        height: 850px;
    }
}

.foto-sensor img {
    margin-right: 50px;
    margin-top: 50px;
}

.portada-item {
    position: relative;
    text-align: center;
}

.portada-text {
    position: absolute;
    color: white;
    top: 10%;
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: 13%;
    max-width: 730px;
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.54);
}




.portada .owl-nav {
    width: 90%;
    position: absolute;
    top: 26%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

.portada .owl-theme .owl-nav [class*=owl-] {
    width: 30px;
    height: 150px;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    /*margin-right: -70px !important;*/
    /*margin-left: -70px !important;*/
}

.portada .owl-theme .owl-next {
    background-image: url("../img/control/slider-right.png") !important;
    float: right;
    height: 150px;
}

.portada .owl-theme .owl-prev {
    background-image: url("../img/control/slider-left.png") !important;
    float: left;
}

.portada .owl-theme .owl-dots {
    position: relative;
    margin-top: 55px;
}

.portada .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ec0928 !important;
}

.portada .owl-theme .owl-dots .owl-dot span {
    background-color: transparent !important;
    border: 1px solid #ec0928;
    width: 17px;
    height: 17px;
}

.container-categoria {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
}


.item-categoria {
    flex-grow: 2;
    margin: auto;
    width: 200px;
}
