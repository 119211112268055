.slide-categorias .owl-dots {
    position: relative;
    top: -100px;
}

.categorias-item {
    position: relative;
    text-align: left;
}

.categorias-text {
    position: absolute;
    color: white;
    top: 10%;
    width: 100%;
    text-align: left;
}

.categorias-text h2 {
    font-weight: 100;
    font-size: 90px;
    max-width: 500px;
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.54);
}

.categorias-text p {
    font-weight: 100;
    font-size: 22px;
    color: #ec0928;
    margin-top: 60px;
}

.productos-categoria {
    margin-top: -37px;
}

.productos-categoria .img-categ{
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
}

.productos-categoria .img-categ:last-child{
    border-bottom: none;
}
.productos-categoria [class*="col-"] {
    margin: 0;
    padding: 0;
}

.productos-categoria img {
    width: 100%;
}

.img-categ button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 65px;
    margin-right: 65px;
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: white !important;
    color: black;
    text-shadow: none !important;
    font-weight: 100;
    font-size: 17px;
    padding-left: 30px;
    padding-right: 30px;
}

@media (min-width: 1200px) {
    .col-lg-3 {
        width: 50%;
    }

}

.categ-cuerpo {
    color: #ec0928;
    padding-top: 60px;
    padding-bottom: 60px;
}
.categ-cuerpo .container-limit{
    padding-left: 27px;
}
.categ-cuerpo h5 {
    font-weight: 100;
    font-size: 30px;
}

.categ-cuerpo h3 {
    font-weight: 500;
    font-size: 90px;
    max-width: 650px;
    margin-top: 30px;
}


.categ-cuerpo p {
    font-weight: 100;
    font-size: 20px;
    max-width: 860px;
}

@media(max-width: 536px){
    .categ-cuerpo h3 {
        font-size: 60px;
    }
    .categ-cuerpo p {
        font-size: 17px;
        padding-right: 15px;
    }
    .img-categ button{
        margin-bottom: 35px;
        margin-right: 31px;
    }
}

@media(max-width: 450px){
    .categ-cuerpo h3 {
        margin-top: 10px;
        font-size: 40px;
    }
    .categ-cuerpo h5 {
        font-weight: 100;
        font-size: 23px;
    }
    .categ-cuerpo p {
        font-size: 14px;
    }
}


.categ-features {
    background-color: white;
    padding-bottom: 50px;
}

.categ-features .items {
    display: block;
    text-align: center;
}

.categ-features .item-feature {
    display: inline-flex;
    margin-top: 13px;
    margin-right: 15px;
    margin-left: 15px;
    vertical-align: middle;

}

.categ-features .items p {
    max-width: 191px;
    color: #ec0928;
    font-weight: 100;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 14px;
    margin-left: 10px;
    text-align: left;
}

.categ-features .items img {
    max-width: 64px;
    max-height: 64px;
}

.categ-features .items img:hover {
    transform: scale(1.03);
}

.productos-categoria .img-categ p{
    position: absolute;
    color: white;
    top: 12%;
    left: 12%;
    font-size: 40px;
    font-family: "Myriad Pro";
    font-weight: 100;
}

@media (max-width: 949px) {

    .invisible1015 {
        display: none;
        visibility: hidden;
        position: absolute;
    }
}

@media(max-width: 978px){
    .productos-categoria .img-categ p{
        font-size: 30px;
    }
}