.noticias{
    width: 100%;
    border-top: 2px solid #ec0928;
    height:100%;
}

.noticias h2{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 45px;
    text-align: center;
    margin-bottom: 45px;
    color: #eb0029;
    text-align: left;
}

.noticias img{
    width: 100%;
}

.noticias .tituloNoticia{

    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 45px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #676868;
    text-align: left;
}

@media(max-width: 615px){
    .noticias .tituloNoticia{
        font-size: 25px;
    }
}

.noticias .copeteNoticia{
    margin: 0 auto;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 13px;
    text-align: center;
    padding-top: 5px;
    color: #676868;
    text-align: left;
}

.noticias div{
    text-align: left;
    position: relative;
}

.noticias .noticia{
    padding-top: 35px;
    position: relative;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 70px;
}

.noticias .masNoticias h2{
    color: white;
}
.noticias .masNoticias{
    background-color: #ea0a2a;
    padding-left: 40px;
    padding-top: 35px;
    position: relative;
    padding-bottom: 150px;
}

.noticias .masNoticias p{
    padding-top: 5px;
    font-size: 13px;
    color: white;
}



.noticias .masNoticias .otrasNoticias{
    border-bottom: 1px solid #f48494;
    padding: 25px 0 20px 0;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    max-width: 200px;
}
.noticias .masNoticias .otrasNoticias:last-child{
    border-bottom: none;
}

.noticias .redesSociales{
    float: right;
    padding-top: 20px;
}

.noticias .redesSociales img{
    width: 35px;
    padding-left: 10px;
}

.noticias a {
    color: white;
    cursor: pointer;
}

.noticias a:hover {
    color: white;
}

.noticias a:active:visited {
    color: white;
}


.noticias .mini-noticia{
    color:white;
    max-width: 300px;
    cursor: pointer;
}

.noticias .mini-noticia h4{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 25px;
    margin-bottom: 30px;
    text-transform: lowercase;
}

@media(max-width: 615px){
    .noticias .mini-noticia h4{
        font-size: 20px;
    }
}
.noticias .mini-noticia h4::first-letter{
    text-transform: uppercase;
}
.noticias .mini-noticia img{
    margin-top: 30px;
}

.noticias .mini-noticia .breve-descripcion{
    font-family: "Myriad Pro";
    font-weight: 100;
    margin-bottom: 30px;
    margin-top: 15px
}

.noticias .mini-noticia{
    border-bottom: 1px solid white;
}

.noticias .mini-noticia:last-child {
    border: none;
}

.noticias .notaCompleta{
    color: #ea0029!important;
    font-weight: 100;
    font-family: "Myriad Pro";
    font-size: 20px;
}

.paginacion ul{
    display: flex;
    list-style: none;
    justify-content: center;
    margin-bottom: 47px;
    padding: 0;
}

.paginacion ul li{
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}
.paginacion ul li.selected:before{
    content: '·';
    color: #eb0727;
    position: absolute;
    bottom: -29px;
    margin-left: 4px;
    font-size: 40px;
}
.paginacion ul a{
    color: #676868;
    font-size: 35px;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.paginacion ul a:hover,.paginacion ul a:visited,.paginacion ul a:focus{
    color: rgba(0, 0, 0, 0.45) !important;
    text-decoration: none;
    outline: none;
}