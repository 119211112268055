.empresa {

}

.empresa-portada {
    background-image: url("../img/bg-aboutus.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;

    height: 95vh;
}

.empresa-portada h1 {
    margin-top: 28%;
    font-size: 100px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;

}

.empresa-portada p {
    color: white;
    font-weight: 100;
    margin-top: 30%;
    font-size: 18px;
    max-width: 450px;
}
@media(max-width: 600px){
    .empresa-portada h1{
        font-size: 75px;
    }
    .empresa-portada{
        background-position-x: -350px !important;
    }
    .empresa-portada p {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .empresa-portada p {
        margin-top: 10%;

    }
}

.miniHr {
    width: 100px;
    background-color: white;
    height: 3px;
    margin-top: 50px;
}

.empresa .separador-img {
    background-image: url("../img/bg-empresa2.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
}

@media(max-height: 900px) {
    .empresa .separador-img{
        height: 30vh;
    }
}
@media(max-height: 900px) and (orientation: landscape) {
    .empresa .separador-img{
        height: 50vh;
    }
}
.ing-nacional {
    background-color: #ea0029;
    padding-top: 90px;
    color: white;
}

.ing-nacional h2 {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 60px;
    margin: 0;
    text-shadow: 1px 1px 14px rgba(0, 0, 0, 0.54);
}

.ing-nacional h3 {
    font-family: "Myriad Pro";
    font-weight: 500;
    font-size: 110px;
    margin: 0;
    text-shadow: 1px 1px 14px rgba(0, 0, 0, 0.54);
}

@media(max-width: 490px){

    .ing-nacional h2 {
        font-size: 25px;
    }

    .ing-nacional h3 {
        font-size: 65px;
    }

}
.ing-nacional p {
    font-weight: 100;
    font-size: 18px;
    max-width: 450px;
}

.features {
    background-color: #ec0928;
    padding-top: 99px;
}

.features .items {
    display: block;
    text-align: center;
}

.features .item-feature {
    display: inline-table;

}

.features .items p {
    max-width: 190px;
    color: white;
    font-weight: 100;
    text-align: center;
    margin: 0 auto;
    font-size: 12px;
}

.features .items img {
    max-width: 215px;
}

.features .items img:hover {
    transform: scale(1.03);
}

.lg-line {
    margin: 0 auto;
    width: 300px;
    background-color: white;
    height: 2px;
    margin-top: 90px;
}

.innovacion {
    background-color: #ea0029;
    /*background-image: url("../img/square.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    color: white;
    min-height: 700px;
    /*height: 70vh;*/
    padding-bottom: 180px;
}

.innovacion h3 {
    font-weight: 100;
    text-align: center;
    font-size: 64px;
    margin-top: 100px;
}
@media(max-width: 716px){
    .innovacion h3{
        font-size: 45px;
    }
}

.innovacion p {
    font-weight: 100;
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
    font-size: 16px;
    margin-top: 25px;
}

.innovacion .valor {
    margin-top: 90px;
}

.sm-line {
    height: 2px;
    background-color: white;
    width: 60px;
    text-align: center;
    margin: 0 auto;
    margin-top: 60px;
}

.separador-galeria {

    height: 300px;
    background-image: url("../img/img-separador.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.mvo {
    background-color: #d9d9d9;
    background-image: url("../img/degrade.png");
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 150px;
    padding-bottom: 170px;
    background-repeat: repeat-x;
    margin-top: -2px;

}

.mvo h4.pane-title {
    text-align: center !important;

}

.mvo .pane-title a {
    font-size: 70px !important;
    margin: 0 auto;
}

.mvo .panel-heading {
    background-color: transparent !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
}

.mvo .panel-default > .panel-heading {
    background-image: none !important;
    color: #ec0928;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}

.mvo .panel-heading a {
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    font-size: 35px;
    cursor: pointer;
    text-align: center;
}

.mvo .panel-group .panel {
    border-radius: 0px;
    border: none !important;
    border-top: 1px solid #ec0928 !important;
    border-bottom: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.mvo .panel-default > .panel-heading + .panel-collapse > .panel-body {
    background-color: transparent !important;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ec0928;
    font-family: Myriad Pro;
    border-top: 1px solid #ec0928;
    font-weight: 100;
    font-size: 18px;
    text-align: center;
}

.mvo .panel-group {
    border-bottom: 1px solid #ec0928;
    max-width: 800px;
    margin: 0 auto;
}

.testimonio .owl-dots {
    position: relative;
    top: -59px;
}

.testimonio-item {
    position: relative;
    text-align: center;
}

.testimonio-text {
    position: absolute;
    color: white;
    top: 26%;
    width: 100%;
    height: 100%;
    text-align: center;
}

.testimonio-text h2 {
    font-weight: 100;
    font-size: 50px;
    max-width: 700px;
    margin: 0 auto;
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.54);
}

.testimonio-text p {
    font-weight: 100;
    font-size: 22px;
}

.miniHr.center {
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;
}

.libro-oro {
    background-image: url("../img/libroOro.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    height: 88vh;
    margin-top: -40px;
}

.libro-oro h1 {
    margin-top: 25%;
    font-size: 100px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.54);

}

@media(max-width: 595px){
    .libro-oro h1 {
        font-size: 70px;

    }
}

.libro-oro p {
    color: white;
    font-weight: 100;
    margin-top: 30px;
    font-size: 18px;
    max-width: 500px;
}

.libro-oro button {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: #ffffff !important;
    color: black !important;
    text-shadow: none !important;
    font-size: 20px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 30px;
}

.empresa .play-container {
    position: absolute;
    width: 100vw;
    height: 101%;
    background-color: black;
    display: flex;
    vertical-align: middle;
    /*opacity: 0.9;*/
    top: 0;
    left: 0;
    z-index: 1;
    background-image: url("../../assets/img/vistaPreviaVideoSobreSensor.jpg");
    background-size: cover;
    /*min-height: 818px;*/
}

.empresa .play {
    z-index: 3;
    display: block;
    vertical-align: middle;
    margin: auto;
    cursor: pointer;
    transition: all .1s ease-in-out;
}


.empresa .play:hover {
    transform: scale(1.05);
}

@media(max-width: 700px){
    .empresa .play{
        transform: scale(0.5);
    }
}
.empresa .owl-carousel .owl-item img {
    margin-top: -2px;
}