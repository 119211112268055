.barraResCel{
    display: none;
    position: fixed;
    top: 37px;
    z-index: 999999;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.barraResCel.smaller{
    top:58px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

@media(max-width: 767px) {
    .barraResCel {
        display: block;
    }
}

.barraResCel .navbar-brand > img{
    max-width: 98px !important;
}

.barraResCel .navbar-default {
    background-color: #ec0928 !important;
    background-image: none !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    width: 100vw;
}

.barraResCel .navbar-default .navbar-collapse, .navbar-default .navbar-form{
    background-color: #ec0928 !important;
}

.barraResCel ul.nav.navbar-nav{
    height: 100vh
;
}
.barraResCel .navbar-default .navbar-collapse, .navbar-default .navbar-form{
    height: 100vh;
    overflow-y: auto;
}


.barraResCel .navbar a {
    font-family: "Myriad Pro" !important;
    font-weight: 300 !important;
    font-size: 13px;
    color: white !important;
    cursor: pointer;
}

.barraResCel .dropdown-toggle, .barraResCel .dropdown-modify{
    font-weight: 500 !important;
    font-size: 20px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.barraResCel a b{
    font-size: 15px;
    margin-left: -10px !important;
}

.barraResCel .navbar-default .navbar-nav > .open > a::after {
    content: '';
    border-bottom: 2px solid white;
    width: 40px;
    position: relative;
    display: none !important;
    text-align: left;
    margin: 1px !important;
}

.barraResCel a.menuItem.active::after {
    content: '' !important;
    border-bottom: 2px solid white !important;
    width: 40px !important;
    position: relative !important;
    display: block !important;
    text-align: left !important;
    margin: 1px !important;
}

@media (max-width: 767px) {
    .barraResCel .navbar-default .navbar-nav a {
        height: 35px;
        width: 300px;
    }
}

.barraResCel .sub-item{
    margin-left: 10px;
}
.barraResCel .semibold.active::after {
    display: none !important;
}

.barraResCel .itemMenu.active::after {
    display: none !important;
}

.barraResCel a.menuItem.active{
    border: none !important;
}

.barraResCel .navbar-default .navbar-toggle{
    border: none !important;
    padding-top: 10px !important;
}

.barraResCel .navbar-toggle .icon-bar{
    width: 23px;
    height: 2px;
}