@import url('./fonts.css');

html {
    overflow-x: hidden !important;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Myriad Pro" !important;
}

h3, h4, p {
    font-variant-ligatures: no-common-ligatures;
    -moz-font-feature-settings: "liga" 0, "clig" 0;
    -webkit-font-feature-settings: "liga" 0, "clig" 0;
    font-feature-settings: "liga" 0, "clig" 0;
}

.container-limit {
    max-width: 1150px; /*Limite del container general para centrar el contenido segun el diseño*/
    /*max-width: 1200px; !*Limite del container general para centrar el contenido segun el diseño*!*/
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
}

.container-limit-md {
    max-width: 1400px; /*Limite del container general para centrar el contenido segun el diseño*/
    /*max-width: 1200px; !*Limite del container general para centrar el contenido segun el diseño*!*/
    margin: 0 auto;
}

/*SUPER CROTADA NECESARIA PARA EL RESPONSIVE,
NO SE SI REACTBOOTSTRAP VINO FALLADO PERO ME METIA UNOS MARGIN NEGATIVOS HORRIBLES Y CALCULABA MAL EL WIDTH
DE LA PAGINA*/
.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.bgRed {
    background: rgb(200, 8, 34) !important; /* Old browsers */
    background: -moz-linear-gradient(top, rgba(200, 8, 34, 1) 0%, rgba(236, 9, 40, 1) 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(200, 8, 34, 1) 0%, rgba(236, 9, 40, 1) 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linedddar-gradient(to bottom, rgba(200, 8, 34, 1) 0%, rgba(236, 9, 40, 1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c80822', endColorstr='#ec0928', GradientType=0) !important; /* IE6-9 */
}

.bgGrey {
    background: rgb(217, 217, 217) !important; /* Old browsers */
    background: -moz-linear-gradient(top, rgb(159, 160, 160) 0%, rgb(217, 217, 217) 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(159, 160, 160) 0%, rgb(217, 217, 217) 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgb(159, 160, 160) 0%, rgb(217, 217, 217) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c80822', endColorstr='#ec0928', GradientType=0) !important; /* IE6-9 */

}

.bgGrey2 {
    background: rgb(103, 104, 104) !important; /* Old browsers */
    background: -moz-linear-gradient(top, rgb(94, 95, 95) 0%, rgb(103, 104, 104) 100%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(94, 95, 95) 0%, rgb(103, 104, 104) 100%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgb(94, 95, 95) 0%, rgb(d103, 104, 104) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c80822', endColorstr='#ec0928', GradientType=0) !important; /* IE6-9 */

}

.center-block {
    float: none !important;
    margin: 0 auto !important;
}

sb {
    font-weight: 500;
}

.lg-line {
    margin: 0 auto;
    width: 300px;
    background-color: white;
    height: 2px;
    margin-top: 90px;
}

.vertical-line {
    height: 110px;
    background-color: #ec0928;
    width: 2px;
}

.red-line {
    height: 2px;
    background-color: #ec0928;
    width: 250px;
    margin-top: 43px;
    margin-bottom: 46px;
}

.red-line2 {
    height: 3px;
    background-color: #ec0928;
    width: 150px;
    margin-top: 43px;
    margin-bottom: 46px;
}

.red-line3 {
    height: 3px;
    background-color: #ec0928;
    width: 240px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.descargas a {
    text-decoration: none !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-border-left {
    border-left: none !important;
    border-right: none !important;
}

.margen-superior {
    margin-top: 99px;
}

@media (max-width: 768px) {
    .margen-superior {
        margin-top: 87px;
    }
}

.owl-nav {
    height: 1px;
}

.modal-backdrop {
    z-index: 99999999999999 !important;
}

.modal {
    z-index: 9999999999 !important;
}

@media (min-width: 992px) {
    .modal-lg {
        width: 780px !important;
    }
}
@media (min-width: 768px) {
    .modal-lg {
        width: 747px !important;
    }
}
