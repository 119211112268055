.clientes{
    background-color: #676868;
    padding-bottom: 70px;
}

.clientes h6{
    text-align: center;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-style: italic;
    padding-top: 90px;
    margin-top: 0px;
    font-size: 20px;
    margin-bottom: 70px;
}

.clientes [class*='col']{ /* class name starts with col-lg */
    text-align: center;
}
.divMarca img {
    position: absolute;
    bottom: 0;
    left: 21%;
    width: 60% !important;
}

.divMarca{
    position: relative !important;
    min-height: 120px !important;
}

.clientes p{
    margin: 100px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 10;
    text-align: center;
}

.clientes .owl-carousel img{
    text-align: center;
}

@media(max-width: 500px){
    .clientes h6{
        margin-bottom: 0px;
    }
    .clientes p{
        margin-top: 80px;
        margin-bottom: 50px;
        margin-right: 40px;
        margin-left: 40px;
    }

}

.clientes .owl-nav{
    width: 100%;
    position: absolute;
    top:40%;
}

.clientes .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: -25px !important;
    margin-left: -25px !important;
    transform: scale(0.6);
}
@media(max-width: 589px){

.clientes .owl-theme .owl-nav [class*=owl-]{
    transform: scale(0.3);
}
}

.clientes .owl-theme .owl-next{
    background-image: url("../img/control/slider-right.png") !important;
    float: right;
}

.clientes .owl-theme .owl-prev{
    background-image: url("../img/control/slider-left.png")!important;
    float:left;
}
