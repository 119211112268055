.contacto-datos{
    background-color: #676868;
    font-family: "Myriad Pro" !important;
    padding: 90px 0px;
    width: 100%;
    background: rgba(84,85,85,1);
    background: -moz-linear-gradient(top, rgba(84,85,85,1) 0%, rgba(66,66,66,1) 2%, rgba(111,112,112,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(84,85,85,1)), color-stop(2%, rgba(66,66,66,1)), color-stop(100%, rgba(111,112,112,1)));
    background: -webkit-linear-gradient(top, rgba(84,85,85,1) 0%, rgba(66,66,66,1) 2%, rgba(111,112,112,1) 100%);
    background: -o-linear-gradient(top, rgba(84,85,85,1) 0%, rgba(66,66,66,1) 2%, rgba(111,112,112,1) 100%);
    background: -ms-linear-gradient(top, rgba(84,85,85,1) 0%, rgba(66,66,66,1) 2%, rgba(111,112,112,1) 100%);
    background: linear-gradient(to bottom, rgba(84,85,85,1) 0%, rgba(66,66,66,1) 2%, rgba(111,112,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#545555', endColorstr='#6f7070', GradientType=0 );
}

.contacto-datos .titulo{
    margin:0 auto;
    max-width: 560px;
    margin-bottom: 40px;
    text-align: center;
}

.contacto-datos .titulo p{
    margin-bottom: 40px;
    font-family: "Myriad Pro" !important;
    text-align: center;
    color: white;
    font-size: 23px;
    font-weight: 100;
    padding: 30px 0px;
}

.contacto-datos .titulo a{
    margin-bottom: 40px;
    font-family: "Myriad Pro" !important;
    text-align: center;
    color: white;
    font-size: 23px;
    font-weight: 100;
    padding: 30px 0px;
    margin: auto;
}

.contacto-datos h2{
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    font-size: 40px;
    color: white;
    text-align: center;
}

.contacto-formulario input{
    font-family: "Myriad Pro" !important;
    font-weight: 200;
    font-size: 18px;
    background: none;
    color: white;
    border-color: white;
    padding: 10px 15px;
    height: auto;
    border-radius: 0px;
}

.contacto-formulario input::placeholder{
    font-family: "Myriad Pro" !important;
    font-weight: 200;
    font-size: 18px;
    color: white;
}

.contacto-formulario textarea{
    font-family: "Myriad Pro" !important;
    font-weight: 200;
    font-size: 18px;
    background: none;
    color: white;
    border-color: white;
    padding: 10px 13px;
    height: auto;
    border-radius: 0px;
}

.contacto-formulario textarea::placeholder{
    font-family: "Myriad Pro" !important;
    font-weight: 200;
    font-size: 18px;
    color: white;
}

.contacto-formulario button{
    font-family: "Myriad Pro" !important;
    font-weight: 200;
    font-size: 24px;
    color: rgba(84,85,85,1);
    border-color: rgba(84,85,85,1);
    padding: 10px 15px;
    border-radius: 0px;
    background-color: white !important;
    padding: 10px 60px;
    margin-top: 40px !important;
    background-image: none;
    box-shadow: 2px 2px 20px #3e3e3e;
}

.contacto-formulario button:hover{
    color: rgba(84,85,85,1);
    border: inherit;
    background-position: inherit;
}

.center-change{
    text-align: center;
}

.bannerEmpresa{
    background-image: url("../img/bg-contacto.png");
    background-size: cover;
    height: 800px;
    width: 100%;
}

.linksDeContacto{
    margin-top:19px;
}
.linksDeContacto div:first-child {
    border-top: 1px solid white;
}
.linksDeContacto div{
    border-bottom: 1px solid white;
    padding-top: 15px;
    padding-bottom: 15px;
}
.linksDeContacto a{
    color:white;
    font-size: 16px;
    font-family: "Myriad Pro";
    font-weight: 200;
}
.linksDeContacto a span{
    margin-left: 15px;
}
.linksDeContacto .spanDerecha{
    float: right;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
    color: white;
    padding-top: 5px;
}

.contacto-datos .fila{
    border-top: 1px solid white;
    /*text-align: center;*/
    color: white;
}

.contacto-datos .fila:last-child{
    border-bottom: 1px solid white;
}
.contacto-datos .fila .linea-right{
    border-right: 1px solid white;
    color: white;
}



.contacto-datos .fila div{
    padding: 30px 0px;
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    font-size: 23px;
}

.contacto-datos .fila img{
    padding-right: 20px;
}

.contacto-datos .fila a{
    margin-bottom: 40px;
    font-family: "Myriad Pro" !important;
    text-align: center;
    color: white;
    font-size: 23px;
    font-weight: 100;
    padding: 30px 5%;
}

@media(max-width: 1495px){
    .contacto-datos .fila a{
        padding: 30px 5%;
    }
}

@media(max-width: 1212px){
    .contacto-datos .fila a{
        font-size: 18px;
    }
}
@media(max-width: 1050px){
    .contacto-datos .fila a{
        font-size: 15px;
    }
}
@media(max-width: 991px) {
    .center-change{
        text-align: left;
    }
    .contacto-datos .fila .linea-right{
        border-right: none !important;
        border-bottom: 1px solid white;
    }
}