.consulta{
    /*background-color: #ec0928;*/
    width: 100%;
    padding-bottom: 75px;
    padding-top: 100px;
}


.consulta h2{
    margin: 0 auto;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 45px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 55px;

}



.consulta ul{
    max-width: 410px;
    /*border: 1px solid white;*/
    margin: 0 auto;
    list-style-type: none;
    text-align: center;
    padding-left: 1px;
    padding-top:20px;
    padding-bottom:20px;
    font-size: 25px;
    letter-spacing: 2px;
}

.consulta li:first-child {
    font-family: "Myriad Pro";
    font-weight: 400;
}
.consulta li{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family:"Myriad Pro";
    font-weight: 100;
}


.consulta .second-box{
    border:none;
    text-align: left;
    padding-top: 0px;
    font-size: 16px;
    padding: 10px;
    letter-spacing: 1px;
}

.consulta .second-box img{
    margin-right: 20px;
}