
.soporte-home {
    background-color: #d9d9d9;
    margin-top: -2px;
}

.soporte-text {
    background-image: url("../img/degrade.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.soporte-img {
    background-image: url("../img/bg-soporte.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    height: 100%;
}

.soporte-home .row {
    display: table;
    width: 100%;
}

@media (max-width: 991px) {
    .soporte-home .row {
        display: block !important;
    }
}

.soporte-home [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
}

.soporte-text {
    color: #ec0928;
    width: 50%;
    padding-top: 100px;
    padding-bottom: 200px;
}

.soporte-text h1 {
    font-family: "Myriad Pro";
    font-weight: 100 !important;
    font-size: 60px;
    padding-left: 15%;
}

.soporte-text p {
    padding-left: 15%;
    font-weight: 100;
    font-size: 18px;
    max-width: 500px;
    margin-top: 30px;
}

.soporte-home .red-line2 {
    margin-left: 15%;
}

.soporte-consulta {
    padding-bottom: 140px;
    padding-top: 100px;
    background-color: #676868;
}

.soporte-consulta h2 {
    color: white;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin: 0 auto;
    margin-bottom: 80px;
    max-width: 700px;

}

.soporte-consulta li {
    list-style: none;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
    font-weight: 100;
    font-size: 19px;
}

.soporte-consulta span {
    padding-left: 10px;
}

.soporte-consulta .center-text {
    text-align: center;
}

.soporte-consulta .linea-sup {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.soporte-consulta .linea-bottom {
    border-bottom: 1px solid white !important;
}

.soporte-consulta .linea-right {
    border-right: 1px solid white;
}

.soporte-consulta a {
    color: white !important;
}

.contacto {
    padding-top: 60px;
    padding-bottom: 60px;
}

.form-limit {
    max-width: 500px;
    margin: 0 auto;
}

.contacto .form-group {
    margin: 0 !important;
    /*height: 45px;*/
}

.contacto .form-control {
    margin: 0;
    -webkit-appearance: inherit;
    border-radius: 0;
    border: 1px solid #ec0928 !important;
    height: 40px;
    color: #ec0928 !important;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.contacto .form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #ec0928;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.contacto .form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #ec0928;
    opacity: 1;
}

.contacto .form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #ec0928;
    opacity: 1;
}

.contacto .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ec0928;
}

.contacto .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #ec0928;
}

.contacto button {
    margin-top: 30px !important;
    position: relative;
    background: none;
    background-color: #ec0928 !important;
    border-radius: 0 !important;
    border: none;
    text-shadow: none;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);

}

@media (max-width: 991px) {
    .soporte-page .center-text {
        text-align: left!important;
    }
    .soporte-consulta .linea-right{
        border: none;
        border-bottom: 1px solid white;
    }
}
