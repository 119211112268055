.productos-std .text-small{
    font-size:17px !important;
    max-width: 450px;
}

.productos-std .productos-caracteristicas{
    padding-bottom: 160px;
}

.productos-std .productos-caracteristicas .caracteristicas-descripcion p{
    font-size: 19px;
}

.sinBorde{
    border: none !important;
}


.productos-std .productos-caracteristicas .img-graficos img{
    margin-top: 40px;
}

/*.productos-std .btnDescarga{*/
    /*padding-top: 70px !important;*/
    /*padding-bottom: 70px;*/
/*}*/


.parking .owl-nav{
    width: 100%;
    position: absolute;
    top:40%;
}

.parking .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: -25px !important;
    margin-left: -25px !important;
    transform: scale(0.6);
}
@media(max-width: 589px){

    .parking .owl-theme .owl-nav [class*=owl-]{
        transform: scale(0.3);
    }
}

.parking .owl-theme .owl-next{
    background-image: url("../img/icon/nextFlecha.png") !important;
    float: right;
}

.parking .owl-theme .owl-prev{
    background-image: url("../img/icon/befoFlecha.png")!important;
    float:left;
}

@media(max-width: 589px){

    .carouselRes .item p{
        font-size: 15px !important;
    }
}

.productos-std .tab-horizontal{
    display: inline-flex;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
}

.productos-std .horiz.tab-vertical-li {
     border-bottom: 1px solid #ea0029;
     padding-bottom: 30px;
     padding-left: 79px;
     padding-right: 30px;
     padding-top: 40px;
     border-right: 1px solid #ea0029;
 }

.productos-std .horiz.tab-vertical-li:last-child {
    border-right: none;
}

.productos-std .animaciones-text{
    color:#676868;
}

.productos-std .animaciones-text h5{
    font-weight: 700;
    font-family: "Myriad Pro";
    font-size: 20px;
}

.productos-std .animaciones-text p{
    font-weight: 100;
    font-family: "Myriad Pro";
}