.react-photo-gallery--gallery img{
    border-right: 1px solid transparent;
}

#lightboxBackdrop{
    z-index: 9999999999999999;
}

.gallery-component .item img{
    width: 50% !important;
}


.gallery-component .owl-nav{
    width: 100%;
    position: absolute;
    top:0%;
}

.gallery-component .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: 0px !important;
    margin-left: 0px !important;
    transform: scale(0.4);
}
@media(max-width: 589px){

    .gallery-component .owl-theme .owl-nav [class*=owl-]{
        transform: scale(0.2);
    }
}

.gallery-component .owl-theme .owl-next{
    background-image: url("../img/control/slider-right.png") !important;
    float: right;
}

.gallery-component .owl-theme .owl-prev{
    background-image: url("../img/control/slider-left.png")!important;
    float:left;
}
