.descargas .container-limit {
    max-width: 900px;
}

.accordionIn {

}

.accordionIn .panel-heading {
    background-color: #585959 !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
}

.faq.descargas .panel-default > .panel-heading {
    background-image: none !important;
    color: white;
    display: block !important;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
}

.faq.descargas .panel-heading a {
    font-family: "Myriad Pro" !important;
    font-weight: 500;
    font-size: 25px;
    cursor: pointer;
    padding-left: 20px;
}

@media(max-width: 600px){
    .faq.descargas .panel-heading a{
        font-size: 17px;
        padding-left: 0;
    }
}
.accordionIn .panel-heading a {
    font-weight: 100 !important;
}

.accordionIn .panel-group .panel {
    border-radius: 0px;
    border: none !important;
    border-top: 1px solid white !important;
    border-bottom: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.faq.descargas .panel-default > .panel-heading + .panel-collapse > .panel-body {
    background-color: transparent !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 0 !important;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 500 !important;
}

.faq.descargas .panel-group {
    border-bottom: 1px solid white;
    margin-bottom: -6px !important;
    margin-top: -1px !important;
}

.faq.descargas ul {
    list-style: none;
    padding: 0;
}

.faq.descargas li {
    border-top: 1px solid white;
    padding-top: 40px;
    padding-bottom: 30px;
}

.faq.descargas li:first-child {
    border: none;
}

.faq.descargas li p {
    padding-left: 120px;
    font-weight: 100;
    font-size: 18px;
    padding-top: 9px;
    text-align: left;
}

@media(max-width: 991px){
    .faq.descargas li p{
        padding-left: 0px;
    }
}

.faq.descargas img {
    margin-bottom: 0 !important;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.faq.descargas li a {
    font-weight: 100;
    font-size: 15px;
    color: white;
    padding-top: 7px;
    border-right: 1px solid white;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration: underline !important;
}

.faq.descargas li .agricultura a {
    font-weight: 100;
    font-size: 14px;
    color: white;
    padding-top: 0px;
    margin-top: -2px;
    border-right: 1px solid white;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration: underline !important;
    width: 97px;

}

.faq.descargas ul li .row .container-btn-descarga:last-child a{
    border: none;
}

.faq.descargas .container-btn-descarga{
    display: inline-grid;
}
.faq.descargas .btn-descarga {
    display: inline-flex;
}

.faq.descargas .btn-descripcion{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 9px;
    width: 97px;
    margin-left: 41px;
    margin-top: -14px;
}

@media(max-width: 600px){
    .faq.descargas .btn-descarga{
        display: inherit;
        text-align: left;
    }
    .faq.descargas li a{
        border: none !important;
    }

    .faq.descargas .container-btn-descarga{
        display: block;
    }

    .faq.descargas .btn-descripcion{
        width: auto;
    }
}
.item-descargas div{
    padding: 8px 6px;
    /*text-align: center;*/
}

.btn-descarga-disabled a{
    color: #7d7e7e !important;
    cursor: not-allowed !important;
}

.btn-descarga-disabled img{
    cursor: not-allowed !important;
}

.consulta-aqui{
    padding-bottom: 140px;
    padding-top: 100px;
}

.consulta-aqui h2{
    color:#ec0928;
    text-align: center;
    font-weight: 100;
    font-size: 50px;
    margin-bottom: 80px;
}

.consulta-aqui li{
    list-style: none;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #ec0928;
    font-weight: 100;
    font-size: 19px;
}

@media(max-width: 991px){
    .consulta-aqui li{
        text-align: left;
    }
    .consulta-aqui .linea-right{
        border-right: none!important;
        border-bottom: 1px solid #ea0029;
    }
}

.consulta-aqui span{
    padding-left: 10px;
}
.consulta-aqui .center-text{
    text-align: center;
}
.consulta-aqui .linea-sup{
    border-top: 1px solid #ec0928;
    border-bottom: 1px solid #ec0928;
}

.consulta-aqui .linea-bottom{
    border-bottom: 1px solid #ec0928 !important;
}
.consulta-aqui .linea-right{
    border-right: 1px solid #ec0928;
}

.lineaBlanca{
    width: 60px;
    border-bottom: 3px solid white;
}

.consulta-aqui a:hover{
    text-decoration: none!important;
}

.rowFlex{
    display: flex;
    align-items: center;
}

@media(max-width: 700px){
    .rowFlex{
        display: block;
    }
}