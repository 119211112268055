@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.animated-background, .animated-backgroundLista {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.animated-backgroundLista {
    height: 393px;
}

.animated-backgroundLista .text-loader {
    background-color: white;
    height: 4px;
    width: 100%;
    margin-top: 52px;
}

.loader-bg {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.loader-bg::before {
    content: '';
    position: absolute;
    bottom: 7%;
    left: 33%;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0px 300px 0px 600px white;
}

.text-loader {
    background-color: white;
    height: 20px;
    width: 100%;
    margin-top: 10px;
}

.titulo-loader {
    background-color: white;
    width: 100%;
    padding: 10px 35px 20px 35px;
    z-index: 0;
    min-height: 126px;
}

.h2-loader {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: -o-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 812px 104px;
    height: 41px;
    position: relative;
    width: 40%;
    top: 20px;
}

.imagen-loader {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: -o-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 812px 104px;
    height: 187px;
    position: relative;
    width: 100%;
    top: 44px;
}

.p-loader {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: -o-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 812px 104px;
    height: 18px;
    position: relative;
    width: 20%;
    top: 30px;

}

.cuerpo-loader {
    background-color: white;
    height: 100%;
    height: 400px;
    padding: 15px;
}

.cuerpo-loader .h2-loader {
    width: 46%;
}

.cuerpo-loader .p-loader {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 34px;
}

.loader-noticias .imagen-loader {
    height: 100px;
}

.loader-noticias .h2-loader:first-child {
    margin-top: 0px;
}

.loader-noticias .h2-loader {
    margin-top: 30px;
}