.productos {
    background-image: url('../img/productos/bg-producto.png');
    background-repeat: no-repeat;
    background-position-y: -726px;
    background-size: cover;
    width: 100%;
    padding-top: 140px;
}

@media (max-width: 1060px) {
    .productos {
        padding-top: 60px;
    }
}

.productos .img-producto {
    /*padding-bottom: 100px;*/
}

.productos .img-producto img {
    margin: 0 auto;
}

.productos .home-producto {
    padding-bottom: 80px;
    padding-left: 15px;
    padding-right: 15px;
}

.productos .tit-producto {
    max-width: 700px;
    font-family: "Myriad Pro";
    position: relative;

}

.productos .tit-producto h1 {
    margin-top: 0 !important;
    line-height: 75px;
    font-weight: 500;
    font-size: 75px;
    color: #ec0928;
    max-width: 515px;
    display: flex;
}
.productos .tit-producto h1 img {
    max-height: 100px;
    position: relative;
    top: -40px;
    margin-left: auto;
}

@media(max-width: 383px){
    .productos .tit-producto h1 img {
        max-height: 65px;
    }
}

@media(max-width: 342px){
    .productos .tit-producto h1 img {
        max-height: 40px;
    }
}

.productos .tit-producto p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 18px;
    color: #eb0029;
}

.productos .seleccion-maquina {
    margin-top: 30px !important;
    font-size: 13px !important;
}

.productos .tit-dsc {
    color: #676868 !important;
    font-size: 16px !important;
    margin-top: 30px !important;
}

.productos .dsc {
    color: #676868 !important;
    font-size: 15px !important;
    font-weight: 100 !important;
    max-width: 500px;
}

.productos .tit-producto h2 {
    font-weight: 500;
    font-size: 33px;
    color: #eb0029;
    margin-top: 33px;
}

.productos .tit-producto h3 {
    margin-top: 0 !important;
    line-height: 75px;
    font-weight: 500;
    font-size: 78px;
    color: #ec0928;
    max-width: 500px;
    display: flex;
}

.productos .tit-producto h3 img {
    max-height: 100px;
    position: relative;
    top: -40px;
    margin-left: auto;
}

@media(max-width: 383px){
    .productos .tit-producto h3 img {
        max-height: 65px;
    }
}

@media (max-width: 1230px) {
    .productos .tit-producto h2 {
        font-size: 28px;
    }
}

@media (max-width: 1060px) {
    .productos .tit-producto h2 {
        font-size: 24px;
        margin-top: 10px;
    }

    .productos .tit-producto h3 {
        font-size: 70px;
        line-height: 65px;

    }

    .productos .tit-producto h1 {
        font-size: 70px;
        line-height: 65px;
    }
}

@media (max-width: 991px) {
    .productos .tit-producto h2 {
        font-size: 30px;
    }

    .productos .home-producto {
        padding-bottom: 30px;
    }
}

@media (max-width: 806px) {
    .productos .tit-producto h3 {
        font-size: 60px;
        line-height: 60px;
    }

    .productos .tit-producto h1 {
        font-size: 50px;
        line-height: 40px;
        padding-top: 15px;
    }

}

@media (max-width: 344px) {
    .productos .tit-producto h1 {
        font-size: 30px;
        line-height: 40px;
        padding-top: 15px;
    }

}

.productos .tit-producto Button {
    margin-top: 5px;
    background: none !important;
    background-color: #ec0928 !important;
    color: white;
    border: none;
    text-shadow: none;
    border-radius: 0;
    font-size: 23px;
    width: 100%;
    font-family: Myriad Pro;
    font-weight: 100;
    padding: 5%;
    /*padding-top: 12px;*/
    /*padding-bottom: 12px;*/
    /*padding-left: 50px;*/
    /*padding-right: 52px;*/
    -webkit-box-shadow: 0px 7px 40px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 7px 40px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 7px 40px -8px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.productos .tit-producto .sub-button {
    text-align: center;
}

.productos .tit-producto .sub-button a {
    color: #676868;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
}

.productos .tit-producto Button:hover {
    transform: scale(1.05);
}

.productos .productos-caracteristicas {
    background-color: white !important;
    padding-top: 90px;
}

.productos .caracteristicas-tecnicas h2 {
    margin-top: -1px !important;
    padding-top: 50px;
    text-align: center;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 24px;
    color: white;
}

.productos .caracteristicas-tecnicas img {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 40px;
}

.productos .productos-caracteristicas {
    font-family: "Myriad Pro";

}

.productos .caracteristicas-descripcion {
    padding-left: 27px;
    /*max-width: 700px;*/
    color: #676868;
}

@media (max-width: 500px) {
    .productos .caracteristicas-descripcion {
        padding-left: 0px;
    }
}

.productos .productos-caracteristicas .referencia {
    font-size: 12px !important;
}

.productos .productos-caracteristicas h3 {
    font-weight: 500;
    font-size: 57px;
    color: #ea0029;
    max-width: 600px !important;
}

@media (max-width: 450px) {
    .productos .productos-caracteristicas h3 {
        font-size: 29px;
    }

    .productos .descargas .dscDescarga h3 {
        font-size: 18px;
    }

    .productos .descargas .dscDescarga h5 {
        font-size: 10px;
        max-width: 150px;
    }
}

.productos .productos-caracteristicas p {
    font-weight: 100;
    font-size: 25px;

}

.productos .destacado.productos-caracteristicas {
    font-family: "Myriad Pro";

}

.productos .destacado.productos-caracteristicas .caracteristicas-descripcion {
    /*padding-left: 100px;*/
    color: #676868;
    max-width: 1080px !important;

}

@media (max-width: 850px) {
    .productos .destacado.productos-caracteristicas .caracteristicas-descripcion {
        padding-left: 10px;
    }
}

.productos .destacado.productos-caracteristicas h3 {
    font-weight: 500;
    font-size: 57px;
    color: #ec0928 !important;
}

.productos .destacado.productos-caracteristicas h4 {
    font-weight: 400;
    font-size: 25px;
    color: #ec0928;
    margin-bottom: 30px;
    transition: all .2s ease-in-out;
}

.productos .destacado.productos-caracteristicas h4:hover {
    transform: scale(1.05);
    transition: all .2s ease-in-out;

}

.productos .destacado.productos-caracteristicas h4:hover {
    transform: none !important;
}

@media (max-width: 450px) {
    .productos .destacado.productos-caracteristicas h3 {
        font-size: 37px;
    }
}

h4.tab-horizontal-item.in {
    color: #ec0928 !important;
}

h4.tab-horizontal-item.in::after {
    content: '·';
    /*border-bottom: 2px solid #eb0029;*/
    width: 40px;
    position: relative;
    display: block;
    margin: 2px auto;
    text-align: center;
}

h4.tab-horizontal-item {
    cursor: pointer;
    font-weight: 400;
    font-size: 25px;
    color: #ffa0b3 !important;
    margin-bottom: 60px;
}
@media(max-width: 991px){
    h4.tab-horizontal-item {
        color: #ec0928 !important;
    }
}
.productos .destacado.productos-caracteristicas p {
    font-weight: 100;
    font-size: 18px !important;
    clear: both;

}

.productos .img-graficos img {
    float: right;
    position: relative;
    right: -10px;
}

@media (max-width: 1060px) {
    .productos .img-graficos img {
        margin: 0 auto;
        float: none

    }
}

.productos .productos-caracteristicas ul {
    list-style-type: none;
    padding-left: 100px;
    margin-top: 50px;
    /*margin-bottom: 120px;*/

}

.productos .productos-caracteristicas li {
    display: flex;
    padding-top: 30px;
    max-width: 1000px;
}

.productos .destacado.productos-caracteristicas li {
    display: flex;
    color: #676868;
    padding-top: 30px;
    max-width: 1000px;
}

.productos .productos-caracteristicas li img {
    max-height: 89px;
}

.productos .productos-caracteristicas li p {
    padding-left: 20px;
    margin-top: 26px;
}

.productos .productos-caracteristicas li b {
    font-weight: 500;
}

.productos .descargas .btnDescarga {

    /* display: -webkit-box; */
    display: -webkit-box;
    max-width: 500px;
    margin: 0 auto;
    cursor: pointer;
    padding-left: 10%;
    padding-top: 13%;
    padding-bottom: 10%;

}

.productos .descargas .dscDescarga {
    color: white;
    padding-left: 20px;
    font-family: "Myriad Pro";
}

@media(max-width: 1324px){
    .productos .descargas .dscDescarga {
        max-width: 220px;
    }

}

.productos .descargas .dscDescarga h3 {
    font-weight: 100;
}

.productos .descargas .dscDescarga h5 {
    font-weight: 100;
}

.productos .bgGris {
    background-color: #d9d9d9;
}

.productos .bgGrisOsc {
    background-color: #676868;
}

.productos .video-guia {
    height: 100%;
    display: flex;
}

@media (max-width: 991px) {
    .productos .video-guia {
        display: block !important;
    }
}

.faq {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#535454+0,676868+53 */
    background: rgb(83, 84, 84); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(83, 84, 84, 1) 0%, rgba(103, 104, 104, 1) 53%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(83, 84, 84, 1) 0%, rgba(103, 104, 104, 1) 53%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(83, 84, 84, 1) 0%, rgba(103, 104, 104, 1) 53%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#535454', endColorstr='#676868', GradientType=0); /* IE6-9 */
    padding-top: 100px;
    padding-bottom: 140px;
}

/*.faq img {*/
    /*margin-bottom: 50px;*/
/*}*/

.faq h2 {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 60px;
    margin-top: 60px;
    color: white;
    margin-bottom: 80px;
    text-align: center;
}

@media (max-width: 580px) {
    .faq h2 {
        font-size: 40px;
    }
}

@media (max-width: 380px) {
    .faq h2 {
        font-size: 30px;
    }
}

.faq .panel-heading {
    background-color: transparent !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
}

.faq .panel-default > .panel-heading {
    background-image: none !important;
    color: white;
    display: table-cell;
    height: 123px;
    vertical-align: middle;
    /*padding-top: 50px;*/
    /*padding-bottom: 50px;*/
}

.faq .panel-heading a {
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    font-size: 18px;
    cursor: pointer;
}

.faq .panel-group .panel {
    border-radius: 0px;
    border: none !important;
    border-top: 1px solid white !important;
    border-bottom: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.faq .panel-default > .panel-heading + .panel-collapse > .panel-body {
    background-color: #555656 !important;
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
    font-family: Myriad Pro;
    font-weight: 100;
}

.faq .panel-group {
    border-bottom: 1px solid white;
}

.faq h6 {
    text-align: center;
    color: #d9d9d9;
    font-family: "Myriad Pro";
    font-size: 25px;
    font-weight: 100;
    margin-top: 50px;
}

.faq Button {
    display: block;
    text-align: center;
    margin: 0 auto;
    background: none !important;
    text-shadow: none !important;
    border-radius: 0 !important;
    color: #d9d9d9;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
    padding: 16px 32px;
    margin-top: 30px;
    transition: all .2s ease-in-out;
}

.faq .center-block a:hover {
    text-decoration: none !important;

}

.lista-trilla {
    padding-left: 3px !important;
    margin-top: 0px !important;
    font-size: 17px;
    /*margin-bottom: 120px;*/
    font-family: "Myriad Pro";
    font-weight: 100;
    list-style: none !important;
}

.lista-trilla li {
    padding-top: 10px !important;
    display: list-item !important;
    /*list-style-type: disc !important;*/
}

.lista-trilla li::before {
    content: "• ";
    color: #eb0029; /* or whatever color you prefer */
    margin-right: 8px;
}

.grey.lista-trilla li::before {
    content: "• ";
    color: gray; /* or whatever color you prefer */
    margin-right: 8px;
}

.nonBullets.lista-trilla li::before {
    content: "";
    margin-right: 0px;
}



.video-guia [class*="col-"] {
    padding: 0 !important;
    margin: 0 !important;
}

.info-iconos p {
    color: white !important;
}

.referencia {
    color: #5e5f5f !important;
    font-size: 12px !important;
    font-weight: 100 !important;
    line-height: 12px;
}

.productos .limite-tit {
    max-width: 580px;
}

.productos .selector-precio {

}

.productos .selector-precio [class*='col-'] {
    border: 1px solid #444444;
}

.productos .selector-precio p {
    cursor: pointer;
    color: #5e5f5f;
    font-weight: 100;
    text-align: center;
}

@media (max-width: 430px) {
    .productos .selector-precio p {
        font-size: 11px;
    }
}

.productos .precios {
    margin-top: 80px;
    margin-bottom: 80px;
}

.productos .section-video {
    padding-top: 80px;
}

.productos .selector-video {
    padding-left: 20px !important;
    margin-top: 0px !important;
}

.productos .selector-video li {
    padding-top: 0px !important;
    line-height: 30px;
    margin-top: 20px;

}

.video-selected {
    border: 1px solid #ea0029;
}

.productos .border-box {
    border-left: 2px solid #ea0029;
    height: 290px;
}

@media (max-width: 768px) {
    .productos .border-box {
        border: none;
    }
}

.productos .selector-video a {
    color: #ea0029;
    font-size: 18px;
    padding-left: 10px;
}

.caracteristicas-descripcion .video-container video {
    margin: 0 auto;
}

.productos .selector-video a:hover, .productos .selector-video a:focus {
    text-decoration: none !important;
}

.section-video .video-container {
    min-height: 300px;
}

.tab-vertical {
    border-right: 1px solid #ea0029;
    /*padding-left: 100px !important;*/
    padding-right: 0px !important;
    padding-top: 100px;
}

.tab-vertical-li {
    border-bottom: 1px solid #ea0029;
    padding-bottom: 30px;
    padding-left: 79px;
    padding-right: 30px;
    padding-top: 40px;
}

.tab-vertical-li:last-child {
    border-bottom: none;
}

.tab-vertical-body {
    display: none;
}

.tab-vertical-body p {
    font-weight: 100;
    font-size: 18px !important;
    clear: both;
}

.tab-vertical-body.in {
    display: block;
}

.tab-vertical-li a {
    color: #ffa0b3;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-top: 20px;
    cursor: pointer;
}
@media(max-width: 991px){
    .tab-vertical-li a {
        color: #ec0928;
    }
}
.in.tab-vertical-li a{
    color: #ea0029;
}
.tab-vertical-li a:hover {
    color: #ea0029;
    text-decoration: none;
}

.tab-vertical-li a:hover.calculadora + #calculadora {
    color: #ea0029;
}

.tab-vertical-li a:hover.pastillas + #pastillas {
    color: #ea0029;
}

.tab-vertical-li a:hover.bomba + #bomba {
    color: #ea0029;
}

.tab-vertical-li a:hover.caudal + #caudal {
    color: #ea0029;
}

.tab-vertical-li.in > #calculadora {
    color: #ea0029;
}

.tab-vertical-li.in > #pastillas {
    color: #ea0029;
}

.tab-vertical-li.in > #bomba {
    color: #ea0029;
}

.tab-vertical-li.in > #caudal {
    color: #ea0029;
}

.tab-vertical-li span {
    float: right;
    padding-right: 10px;
    color: transparent;
    margin-top: -30px;
}

.tab-vertical-img {
    width: 100%;
}

.tab-vertical-horizontal {
    border-right: 1px solid #ea0029;
    text-align: center;
    min-height: 40px !important;
    display: flex;
    align-items: center;
}

.tab-vertical-horizontal b {
    padding: 5px;
    font-weight: 300 !important;
}

.tab-vertical-horizontal-item {
    cursor: pointer;
    font-size: 18px;
    color:#ffa0b3;
}
.in.tab-vertical-horizontal-item {
    color:#ec0928;
}

.tab-vertical-horizontal-item.in::after {
    content: '·';
    /*border-bottom: 2px solid #eb0029;*/
    width: 40px;
    position: relative;
    display: block;
    margin: 5px auto;
    text-align: center;
}

.background .tab-vertical-horizontal-item {
    cursor: pointer;
    font-size: 11px;
    margin: 0 auto;
}

.background .tab-vertical-horizontal-item.in {
    background-color: #eaeaea;
}

.background .tab-vertical-horizontal-item.in:after {
    display: none;
}

.tab-vertical-horizontal-img {
    width: 100%;
    display: none;
}

.tab-vertical-horizontal-img.in {
    width: 100%;
    display: block;
}

.seccion {
    margin-top: 100px;
}

.listaIndice p {
    padding: 1px;
    margin: 0px;
    font-size: 15px !important;
}

.productos .iconos {
    text-align: center;
    padding-bottom: 140px;
    padding-top: 140px;
    color: white;
}

.productos .iconos p {
    margin: 40px auto;
    font-size: 13px;
    max-width: 230px;
    text-align: center;
    z-index: 44;
}

.productos .iconos img {
    transition: all .1s ease-in-out;
}

.productos .iconos img:hover {
    transform: scale(1.13);
    transition: all .1s ease-in-out;
}

.productos .divisor-lateral::after {
    content: "";
    border-right: 2px solid white;
    position: absolute;
    top: 37%;
    left: 0;
    height: 106px;
}

@media (max-width: 991px) {
    .productos .divisor-lateral::after, .productos .divisor-lateral::before {
        display: none;
    }
}

.productos .divisor-lateral::before {
    content: "";
    border-right: 2px solid white;
    position: absolute;
    top: 37%;
    right: 0;
    height: 106px;
}

.film-container .play-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    vertical-align: middle;
    opacity: 0.3;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 3s ease-in-out;
}

.film-container .play {
    z-index: 3;
    display: block;
    vertical-align: middle;
    margin: auto;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

@media (max-width: 522px) {
    .film-container .play {
        width: 50px;
    }
}

.film-container .play:hover {
    transform: scale(1.05);
}

.caracteristicas-tecnicas a:hover {
    text-decoration: none;
}

.desaparecer {
    visibility: hidden;
}

@media (max-width: 992px) {

    .productos .descarga-catalogo {
        display: block !important;
    }
}

.productos .descargas a {
    margin: 0 auto;
    /*margin-left: 15%;*/
}

.productos .descargas a.incompleto {
    /*margin: 0 auto;*/
    margin-left: 15% !important;
}

.glyphicon-stop:before {
    position: absolute;
    top: 0px;
}

.sliderDestacados .owl-theme .owl-dots {
    display: none;
}

.sliderDestacados .owl-theme .owl-dots .owl-dot span {
    background-color: transparent !important;
    border: 1px solid #ec0928;
    width: 17px;
    height: 17px;
}

.sliderDestacados .owl-theme .owl-dots .owl-dot.active span, .sliderDestacados .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ec0928 !important;
}

@media (max-width: 991px) {
    h4.tab-horizontal-item.in::after {
        display: none;
    }

    .sliderDestacados .owl-theme .owl-dots {
        display: block;
    }

    .owl-theme .owl-dots .owl-dot span {
        width: 10px !important;
        height: 10px !important;
    }
}

.caracteristica-cables {
    padding-left: 10%;
}

@media (max-width: 550px) {
    .caracteristica-cables {
        padding-left: 5%;
    }
}



.img-galeria{
    cursor:pointer;
}