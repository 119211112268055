.footer{
    background-color: #ec0928;
    color: white;
    padding-top: 100px;
}
.footer .footer-red{
    padding-bottom: 100px;
}
.footer .col-linea{
    border-top: 1px solid white;
    padding-top: 20px;
    max-width: 219px;
    line-height: 28px;
}
.footer h4{
    font-family: "Myriad Pro";
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 40px;
}

/*h4.otro-margin{*/
    /*margin-bottom: 24px;*/
/*}*/
.footer ul{
    padding-left: 0;
}
.footer li:first-child{
    font-weight: 500;
}
.footer li{
    list-style-type: none;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.footer li a{
    color: white;
}

.footer .footer-black{
    background-color: black;
    text-align: center;
}
.footer .footer-black p{
    text-align: center;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 200;
    padding-top: 25px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

.footer-consulta{
    padding-bottom: 80px;
}


.footer-consulta li{
    list-style: none;
    padding-top: 30px;
    padding-bottom: 30px;

}

.footer-consulta span{
    padding-left: 10px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 13px;
}

.footer-consulta .linea-sup{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.footer-consulta .linea-bottom{
    border-bottom: 1px solid white;
}
.footer-consulta .linea-right{
    border-right: 1px solid white;
}

.footer-consulta h5{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size:25px;
    margin-top: 0;
    margin-bottom: 30px;
}
.visible-1148{
    display: none;
}
.linea-bottom{
    border-bottom: none !important;
}
@media(max-width: 1148px){
    .invisible-1148{
        display: none;
    }
    .mod-1148{
        width: 33.33% !important;
    }
    .visible-1148{
        display: block;
    }
    .del-linea{
        border: none!important;
    }
    .linea-bottom{
        border-bottom: 1px solid white !important;
    }

}

@media(max-width: 675px){
    .footer-consulta span{
        font-size: 10px !important;
    }
}

.footer-consulta-res{
    display: none;
    padding-bottom: 60px;
}
.footer-consulta-res h5{
    margin-bottom: 30px;
}

.container-contacto{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
}
.item-contacto {
    flex-grow: 2;
    margin: auto;
    cursor: pointer;
}


@media (max-width: 504px) {
    .footer-consulta{
        display: none;
    }
    .footer-consulta-res{
        display: block;
    }

    .col-12-personalizada{
        width: 100% !important;
    }
    .footer .col-linea{
        margin: 0 auto!important;
    }
}

.footer-black.taco a{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10px;
    text-align: right;
    float: right;
    padding-right: 20px;
    padding-bottom: 10px;
}