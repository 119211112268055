.sliderFinan .owl-carousel .owl-item img{
    width: auto;
    display: block;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
}

.sliderFinan .owl-carousel .owl-item .item{
    height: 200px;
    /*width: 200px;*/
    width: 100vw;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}



.sliderFinan .owl-nav{
    width: 100%;
    position: absolute;
    top:18%;
    z-index: -1;
}

.sliderFinan .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: -30px !important;
    margin-left: -30px !important;
}

.sliderFinan .owl-theme .owl-next{
    background-image: url("../img/icon/nextFlecha.png") !important;
    float: right;
}

.sliderFinan .owl-theme .owl-prev{
    background-image: url("../img/icon/befoFlecha.png") !important;
    float:left;
}

.sliderFinan .owl-theme .owl-dots{
    position: relative;
    margin-top: 55px;
}

.sliderFinan .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ec0928 !important;
}

.sliderFinan .owl-theme .owl-dots .owl-dot span {
    background-color: transparent !important;
    border: 1px solid #ec0928;
    width: 17px;
    height: 17px;
}

@media(max-width: 1257px){
    .sliderFinan .owl-theme .owl-nav [class*=owl-]{
        margin-right: 70px !important;
        margin-left: 70px !important;
    }
}

@media(max-width: 895px){
    .sliderFinan .owl-theme .owl-nav{
        display: none !important;
    }
}

@media(max-width: 501px) {
    .sliderFinan h2{
        font-size: 33px!important;
        margin-bottom: 30px !important;
    }
    .sliderFinan h4{
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }
    .sliderFinan .owl-theme .owl-dots{
        margin-top: 0px !important;
    }
    .sliderFinan h5{
        font-size: 11px;
    }
}
