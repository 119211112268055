.barra {
    position: fixed;
    top: 48px;
    z-index: 9999999;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.barra.smaller{
    top:68px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.navbar-default {
    background-color: #ec0928 !important;
    background-image: none !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    width: 100vw;
    height: 79px;

}

.navbar .nav,
.navbar .nav > li {
    float: none;
    display: flex;
    vertical-align: top;
    margin: 0 auto;
    max-width: 1260px; /*limite de la barra*/
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.centerNav .active {
    background-image: none !important;
    background-color: transparent !important;
    padding-bottom: 2px;
}

.navbar-default .navbar-nav a {
    padding-right: 0px;
    padding-left: 0px;
}

.navbar img.logo {
    margin-top: -14px;
}

.navbar a {
    font-family: "Myriad Pro";
    font-weight: 700;
    font-size: 14px;
    color: white !important;
    cursor: pointer;
}

.navbar a:hover {

}

.centerNav {
    text-align: center;
    margin-top: -10px
}

@media (max-width: 768px) {
    .centerNav {
        margin-top: 0px;
    }

    .navbar-default {
        height: 50px;
    }

}
.barra .navbar.navbar-default{
    padding-top: 13px;
}
.centerNav .container {
    width: 100vw;
    padding: 0;
    margin: 0;
}

.navbar-toggle {
    padding: 6px !important;
    width: 37px !important;
    height: 32px !important;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: white !important;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent !important;
}

.navbar-default .navbar-toggle {
    border-color: white;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .active > a {
    background: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    text-decoration: none;
    border: none;
}

.navbar-default .navbar-nav > .open > a::after {
    content: '';
    border-bottom: 2px solid white;
    width: 40px;
    position: relative;
    display: block;
    margin: 5px auto 0px auto;
    text-align: center;
}

a.menuItem.active::after {
    content: '';
    border-bottom: 2px solid white;
    width: 40px;
    position: relative;
    display: block;
    margin: 5px auto 0px auto;
    text-align: center;
}

@media (max-width: 767px) {
    .navbar .nav,
    .navbar .nav > li {
        display: list-item;
    }

    .centerNav {
        text-align: left;
    }

    .navbar-default .navbar-nav a {
        height: 35px;
        width: 130px;
    }

    .centerNav .active {
        border-bottom: 3px solid white;
        padding-bottom: 2px;
    }
}

.barra .dropdown-menu {
    /*background-color: #676868;*/
    background-color: transparent;
    margin-top: 11px !important;;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: none;
    min-width: 250px;
}

.barra .dropdown-menu .row {
    min-height: 64px;
    padding-top: 11px;
    padding-bottom: 11px;
    border: 1px solid black;
}

.barra .dropdown-menu table {
    /*width: 100%;*/
    table-layout: fixed;
    width: 100%;
}

.barra .dropdown-menu tr {
    height: 57px;
    width: 100%;
    display: table-row;
}

.barra .dropdown-menu td {
    border: 1px solid black;
    padding-left: 10px;
    width: 250px;
    background-color: #676868;
}

.barra .dropdown-menu img {
    vertical-align: middle;
    margin: 6px auto;
    display: block;
    max-width: 100%;
    height: auto;
}

.barra .dropdown-menu .col-md-8 {
    /*padding-top: 9px;*/
}

.barra .dropdown-menu a.semibold {
    font-weight: 300;
}

.barra .dropdown-menu a {
    font-family: "Myriad Pro";
    font-weight: 100;
}

.barra .tdMenuDropdown {
    /*background-color: #444444;*/
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10px !important;
    font-style: normal;
    padding: 0px !important;
}

.barra .tdMenuDropdown a {
    font-size: 10px !important;

}

.barra .menuDropdown {
    padding-right: 0px !important;
}

.barra .semibold.active::after {
    content: '';
    border-bottom: 2px solid white;
    width: 40px;
    position: relative;
    display: block;
    margin: 5px auto 0px 0px;
    text-align: center;
}

.barra .itemMenu.active::after {
    content: '';
    border-bottom: 2px solid white;
    width: 40px;
    position: relative;
    display: block;
    margin: 1px auto 0px 0px;
    text-align: center;
}
@media(max-height:690px){
    .barra .dropdown-menu {
        left: -105px;
        height: calc(100vh - 188px);
        overflow-y: auto;
    }
}

@media (max-width: 992px) {
    .barra {
        display: none;
    }
}

.barra .dropdown-menu{
    border: none !important;
}

.item-empty{
    border: none !important;
    background-color: transparent !important;
}
.barra .row{
    display: table;
}
.barra [class*="col-"]{
    float: none;
    display: table-cell;
    vertical-align: middle;
}