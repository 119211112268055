.header {
    color: white;
    background-color: #676868;
    position: fixed;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 38px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.header.smaller {
    height: 58px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.header .text-to-right {
    text-align: right;
    padding-right: 0px;
}

.header ul {
    display: inline-flex;
    list-style-type: none;
    padding-left: 0px;
    margin-left: -33px;
    margin-bottom: 0px;
    height: 100%;
}

@media(max-width: 1250px){
    .header ul{
        margin-left: -5px;
    }
}
@media(max-width: 1250px){
    .header ul{
        margin-left: 0px;
    }
}
@media(max-width: 365px){
    .header ul{
        margin-left: -10px;
    }
}
.header li {
    padding-left: 5px;
    padding-right: 5px;
    vert-align: middle;
    align-self: center;
}

.header a {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 11px;
}

.header select {
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 11px;
    -webkit-appearance: none !important;
    /*border: 1px solid #f57c8c;*/
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: transparent;
    background: url("../img/drop.png") no-repeat left center;
    background-position-x: 4px;
    padding-left: 15px;
    padding-top: 2px;
    height: 20px;
}

.header .habla-nosotros p{
    margin: 0;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    transition: all .2s ease-in-out;
}

.header .habla-nosotros img{
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
    transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;
}

.header .habla-nosotros.smaller img{
    width: auto;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;
}


.header .habla-nosotros.smaller p{
    font-size: 27px;
    transition: all .2s ease-in-out;
}
a.menuHeader:active {
    text-decoration: none;
    color: white !important;
}

a.menuHeader:hover {
    color: white !important;
}

a.menuHeader.active {
    text-decoration: underline;
    color: white !important;
}

@media (max-width: 340px) {

    .selector-lenguaje {
        width: 28px;
    }
}