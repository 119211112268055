.section-noticia {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section-noticia .slider-noticias {
    background-color: #d9d9d9;
    max-width: 270px;
    padding-bottom: 15px;
}

.section-noticia .big-img{
    max-width: 100%;
    height: auto;
}

.section-noticia .slider-noticias h2 {
    color: #676868;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding-top: 15px;
    padding-left: 15px;
    margin-top: 0;
}

.section-noticia .mini-noticia img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.section-noticia .mini-noticia h4 {
    color: #676868;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 14px;
}

.section-noticia .mini-noticia {
    padding-top: 20px;
    cursor: pointer;
}

.section-noticia .mini-noticia .breve-descripcion {
    color: #676868;
    font-family: "Myriad Pro";
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
}

.section-noticia .container-mini-noticia {
    border-bottom: 1px solid #bdbdbd;
    margin: 10px;
    padding-bottom: 15px;
}

.section-noticia .slider-noticias .mini-noticia:last-child .container-mini-noticia {
    border-bottom: none;
}

.section-noticia .noticia{
    padding-right: 50px;
}

.section-noticia .noticia .fecha{
    text-align: right;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 15px;
    color: #676868;
}

.section-noticia .tituloNoticia{
    font-size: 36px;
    color:#676868;
    font-family: "Myriad Pro";
    font-weight: 100;
}

@media(max-width: 615px){
    .section-noticia .tituloNoticia{
        font-size: 26px;
    }
}

.section-noticia .redesSociales{
    text-align: right;
    padding-top: 20px;
}

.section-noticia .redesSociales img{
    width: 35px;
    padding-left: 10px;
}

.section-noticia .linea-gris{
    background-color: #a0a1a1;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
}

.section-noticia .breve-descripcion{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
    color: #676868;
}